import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import SpinningSpark from '../shared/components/SpinningSpark';
import { useUser } from '../auth/User.context';

export default function Home() {
  const {
    state: {
      adminPanelActivePermissions,
      authenticated,
      userIsLoading,
      userPermissions,
    },
  } = useUser();

  const permissionsToRouteMap = {
    'Manage Users': '/users',
    'Manage Stores': '/stores',
    'Manage Priorities': '/priorities',
    'Manage Post of the Day': '/potd',
    'Manage Decision': '/videoReviewDashboard',
    'View Video Report': '/powerbi',
  };

  const navigate = useNavigate();

  useEffect(() => {
    const mappedAdminPanelActivePermissions = adminPanelActivePermissions.map(
      (permission) => permission.title
    );
    const mappedUserPermissions = userPermissions.map(
      (permission) => permission.title
    );

    if (!userIsLoading && authenticated) {
      const match = mappedUserPermissions.find((permission) =>
        mappedAdminPanelActivePermissions.includes(permission)
      );

      if (match) {
        navigate(permissionsToRouteMap[match]);
      } else {
        navigate('/unauthorized');
      }
    }
  }, []);

  return (
    <Grid
      container
      direction="column"
      style={{
        minHeight: '100vh',
        marginTop: 20,
        alignItems: 'center',
        justify: 'center',
      }}
    >
      <SpinningSpark />
    </Grid>
  );
}
